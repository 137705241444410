<template>
  <b-card>
    <div
      v-for="(item, index) in changelog.reverse()"
      :key="item.version"
    >
      <hr v-if="index !== 0" />
      <h5>{{ item.version }}</h5>
      <ul>
        <li
          v-for="change in item.changes"
          :key="item.version + change"
        >
          {{ change }}
        </li>
      </ul>
    </div>
  </b-card>
</template>

<script>
import { BCard } from 'bootstrap-vue'

export default {
  components: {
    BCard,
  },
  computed: {
    changelog() {
      return [
        {
          version: '0.1',
          changes: ['Created Changelog'],
        },
        {
          version: '0.2',
          changes: ['Μηχανισμός εξαγωγής σε CSV/XLS', 'Μηχανισμός Datepicker'],
        },
        {
          version: '0.9.2',
          changes: [
            'Προσθήκη Σελίδων Τιμολογίων',
            'Προσθήκη Σελίδων Συνδρομών',
            'Προσθήκη Σελίδων Χορηγών',
            'Προσθήκη Σελίδων Προσώπων',
            'Προσθήκη Φίτρων σε Καρτέλα Μελών',
            'Διόρθωση μεταφράσεων',
            'Διόρθωση ημερολογίου με δυνατότητα επεξεργασίας εγγραφής(in work)',
            'Διόρθωση πεδίων Συνεδρίου',
          ],
        },
        {
          version: '0.9.3',
          changes: [
            'Προσθήκη Οντότητας Νομών',
            'Προσθήκη Οντότητας Εργασιακών Φορέων',
            'Προσθήκη Οντότητας Ονομάτων Εργασιακών Φορέων',
            'Προσθήκη Οντότητας Μεταφορέων',
            'Προσθήκη Οντότητας κλινικών',
            'Προσθήκη Οντότητας Θέσεων',
            'Διόρθωση μεταφράσεων',
            'Added user payments functionality',
            'Completed congress photos import',
            'Added congress image upload functionality',
            'Added member image upload functionality',
            'Congress image save fixes',
          ],
        },
        {
          version: '0.9.4',
          changes: [
            'Congress upload to website functionality',
            'User save fixes',
            'Added User search by member code',
            'Added User search by email',
            'Added member type filter for members',
            'Added Working Group filter for members',
            'Added hospital filter for members',
            'Added hospital type filter for members',
            'Added job Position filter for members',
            'Added prefecture filter for members',
          ],
        },
        {
          version: '0.9.5',
          changes: [
            'Email functionality',
            'Members import',
            'Members default values in insert',
            'Hospital entity loading fix',
            'Login page rebranding',
            'Reset password functionality',
            'Set production URLs',
          ],
        },
        {
          version: '0.9.6',
          date: '2022-02-23',
          changes: [
            'Dependency upgrades',
            'Spring Boot upgrade to 2.6.x',
            'Database queries lazy loading speed optimization',
            'Publish event to website fix',
            'Event photo gallery to website functionality',
            'Modal datepicker autocomplete fix',
            'Save user settings fix',
            'Update user language endpoint fix',
            'Unlock user functionality',
            'Menu refactoring',
            'Hospital type save fix',
            'Members without hospital query fix',
            'Added send reset password email functionality',
            'Added translations',
            'Website adjacent links fix',
          ],
        },
        {
          version: '0.9.7',
          date: '2022-02-26',
          changes: [
            'Update user email fix',
            'Geocoding functionality',
            'Dynamic Google maps addition for members',
            'Dynamic Google maps addition for venues',
            'Dynamic Google maps addition for sponsors',
            'Members last name set as link',
            'Entities date filters fix',
            'Entity lists ordered alphabetically',
            'Added congress services section',
            'Entity local storage optimization',
            'Congress menu navigation fix',
            'Congress lang default field',
            'Upload to website functionality fixes',
            'Remove from website functionality',
            'View in website functionality',
            'Update website functionality',
            'Set wordpress category fix',
            'Added congresses photo gallery section',
          ],
        },
        {
          version: '0.9.8',
          date: '2022-03-02',
          changes: [
            'Global search functionality',
            'Added website link in bookmarks',
          ],
        },
        {
          version: '0.9.9',
          date: '2022-03-21',
          changes: [
            'Set language fix on events upload to website',
            'Set start-end date fix on events upload to website',
            'Webinars pagination fix',
            'Imported user working groups',
            'Working groups mapping to website entries',
            'Added corresponding webinars to each working group',
            'Added foreign scientific journals section',
          ],
        },
        {
          version: '1.0.0',
          date: '2022-04-10',
          changes: [
            'Added all videos section',
            'Added Video working group property',
            'Added Video speaker property',
            'Added Video speaker, working group, congress filters',
            'Updated video list columns',
            'User list database fetch speed optimization',
            'Spring Boot upgrade to 2.6.5',
            'Added Digital Book functionality',
          ],
        },
        {
          version: '1.0.1',
          date: '2022-04-16',
          changes: [
            'Added Web TV section',
            'Added conditional appearance of videos or photos section per congress',
            'Status sync with website',
            'Added fallback image for congresses',
            'Added 3 new working groups',
          ],
        },
        {
          version: '1.0.2',
          date: '2022-05-08',
          changes: [
            'Added filters collapse functionality',
            'Added clinical cases custom post type',
            'Added working group taxonomy for events',
            'Added Δόκιμο Μέλος member type',
            'Added Subscription Types',
            'Added Subscription End Support',
            'Added Subscription History',
            'Added Video Congress room field',
            'Added Video date field',
            'Congress Room save fix',
            'Congress Room insert default values',
            'Added Rooms list congress filter',
            'Added filters on congress video gallery',
            'Added date from & date to filters on general video gallery',
            'Video min & max date validation',
            'Spring Boot upgrade to 2.6.6',
            'Appled npm modules security patches',
          ],
        },
        {
          version: '1.0.3',
          date: '2022-05-12',
          changes: [
            'New Venue save fix',
            'Update date save for various entities fix',
            'Video available dates loading after refresh fix',
            'Video list loading after save fix',
            'Added speaker filter to public API for videos',
            'Added working group filter to public API for videos',
            'Added room filter to public API for videos',
            'Added date filter to public API for videos',
          ],
        },
        {
          version: '1.0.4',
          date: '2022-08-25',
          changes: [
            'Backend - Spring Boot upgrade to 2.7.3',
            'Backend - FTP upload exception handling',
            'Backend - Image upload rewrite',
            'Frontend - npm modules updates',
            'Frontend - File upload on entity insert fix',
          ],
        },
        {
          version: '1.0.5',
          date: '2022-09-25',
          changes: [
            'Backend - ePay payment success endpoint',
            'Backend - ePay payment failure endpoint',
            'Backend - ePay payment digest generation',
            'Backend - ePay transactions save',
            'Backend - Auto update subscription upon user manual payment',
            'Backend - Auto update subscription ePay payment',
            'Backend - Start subscription date from payment date when it has expired',
            'Frontend - Cleared mock data',
            'Frontend - Set max date validation for custom payments',
            'Frontend - Added payment methods',
            'Frontend - Added user payments',
          ],
        },
        {
          version: '1.0.6',
          date: '2022-09-25',
          changes: [
            'Backend - Voting entities addition',
            'Backend - plain member rights fix',
            'Frontend - plain member menu improvements',
          ],
        },
        {
          version: '1.0.7',
          date: '2022-10-16',
          changes: [
            'Backend - added participants entity',
            'Backend - added video & congress image user group rights',
            'Backend - connected speakers with participant entities',
            'Frontend - added participants menu',
            'Frontend - authority validation on menu entries',
            'Frontend - Last name column link fix',
            'Frontend - Voting category entity addition',
            'Frontend - Voting entity addition',
            'Frontend - Added translations',
            'API - Updated speakers endpoint returning congress participants, nstead of users',
          ],
        },
        {
          version: '1.0.8',
          date: '2022-10-20',
          changes: [
            'Frontend - clear client-side cache mechanism',
            'Frontend - components formatting update',
            'Frontend - Fetch speakers list fix',
          ],
        },
        {
          version: '1.0.9',
          date: '2022-12-19',
          changes: [
            'Backend - Spring Boot upgrade to 2.7.6',
            'Backend - Added banks list endpoint',
            'Backend - Added bank account entity',
            'Backend - Added payment relations to congress and bank account',
            'Backend - Pylon ERP payments integration',
            'Backend - Pylon ERP members integration',
            'Frontend - npm dependencies upgrades',
            'Frontend - Bootstrap Vue upgrade',
            'Frontend - Added bank account menu',
          ],
        },
        {
          version: '1.1.0',
          date: '2022-12-30',
          changes: [
            'Add: Below specialty years reduced amount Subscription support',
            'Add: Balance field for users',
            'Add: Subscription expiry date & balance information on member subscription page',
            'Add: Force balance payment along with subscription renewal for electronic payments',
            'Add: Automatically insert payment to Pylon API after ePay payment',
            'Add: Automatically insert payment to Pylon API after manual payment',
            'Update: Synced all lookup data tables with Pylon ERP unique codes',
            'Update: Removed member type field for users',
            'Update: Made working group field larger for users',
          ],
        },
        {
          version: '1.1.1',
          date: '2023-01-06',
          changes: [
            'Add: Profile link on members menu',
            'Add: Automatically update user info on Pylon ERP through API',
            'Add: Automatically insert new users to Pylon ERP through API',
            'Add: Synced all members with current status in Pylon ERP',
            'Add: Set subscription end date and price for all members',
            'Add: Added parameter for grace period before a member becomes inactive after subscription end date',
            'Add: Added daily scheduler for setting members with expred subscription as inactive',
            'Add: Request reset HCS member password from website',
            'Update: Member code set as optional field for users',
            'Fix: Private Office save',
            'Fix: Private Clinic save',
          ],
        },
        {
          version: '1.1.2',
          date: '2023-01-11',
          changes: [
            'Add: Show/hide columns functionality',
            'Add: Export all columns on Excel/CSV export',
            'Add: Delete entity modal confirmation',
            'Add: Balance column on users list screen',
            'Add: Default value for manual subscription payments',
            'Update: Hide congress for subscription payments and vice versa',
            'Update: Set default entity actions as inline',
            'Update: Phone client-side validation',
            'Update: Extra validation message shown in toast',
            'Update: Exclude Member id update from Pylon ERP integration',
            'Update: Default descending sort on entities',
            'Update: Export all rows on Excel/CSV export',
            'Fix: Allow negative balance on frontend',
            'Fix: Subscription price fix on some occasions',
          ],
        },
        {
          version: '1.1.3',
          date: '2023-01-13',
          changes: [
            'Add: Subscription price column for users',
            'Update: Optimize Pylon member updates',
            'Fix: Payments list not shown on some occasions',
          ],
        },
        {
          version: '1.1.4',
          date: '2023-01-30',
          changes: [
            'Add: Login hyperlink to website',
            'Add: Welcome user e-mail',
            'Add: User rights translations',
            'Add: List loading speed optimization',
            'Fix: Fetch users with no member code',
            'Fix: Member type save',
            'Fix: Delete entities through modal',
            'Fix: User group save',
          ],
        },
        {
          version: '1.2.0',
          date: '2023-06-09',
          changes: [
            'Add: Java SDK 17 support',
            'Add: Spring Boot upgrade to 2.7.12',
            'Add: VueJs upgrade to 2.7.14',
            'Add: Questionnaire Category entity',
            'Add: Questionnaire Unit entity',
            'Add: Questionnaire entity',
            'Add: Questionnaire section entity',
            'Add: Questionnaire field entity',
            'Add: Questionnaire user roles functionality',
            'Add: Return to list support for all entities',
            'Add: Radio input type support',
            'Add: Form field suffix support',
            'Add: Form field help text support',
            'Update: Extra validation message shown in toast',
            'Update: Model mapping speed optimization',
            'Update: User login speed optimization',
            'Update: Bootstrap Vue loading optimization',
            'Update: Scheduler tasks refactoring on backend',
            'Update: More indicative toast server-side validation messages',
            'Update: User to usergroup relation',
            'Fix: Congress image save',
          ],
        },
        {
          version: '1.2.1',
          date: '2023-06-14',
          changes: ['Fix: PDF upload'],
        },
        {
          version: '1.2.2',
          date: '2023-06-19',
          changes: [
            'Add: Publish questionnaire answer functionality',
            'Add: Required field option for questionnaires',
            'Add: Required field validation before publish action',
            'Add: Questionnaire answers export functionality',
            'Add: Questionnaire answers published filter',
            'Update: Questionnaire answers toggle columns functionality',
            'Update: Questionnaire answers list columns',
          ],
        },
        {
          version: '1.2.3',
          date: '2023-06-29',
          changes: [
            'Update: Role based access control for questionnaires',
            'Fix: Allow multiple answers for same questionnaire',
          ],
        },
        {
          version: '1.2.4',
          date: '2023-07-04',
          changes: ['Add: Questionnaire answers fields'],
        },
        {
          version: '1.2.5',
          date: '2023-07-21',
          changes: [
            'Add: Min & max value validation for questionnaire fields',
            'Update: Disallow deleting published questionnaire entries',
            'Update: View button for published questionnaire entries',
          ],
        },
        {
          version: '1.2.6',
          date: '2023-07-29',
          changes: ['Fix: Questionnaire fields save'],
        },
        {
          version: '1.2.7',
          date: '2023-08-08',
          changes: [
            'Add: Support for dropdowns with multiple answers on questionnaires',
            'Update: Required field asterisk for radio buttons',
          ],
        },
        {
          version: '1.2.8',
          date: '2023-08-11',
          changes: ['Add: Set high risk patient optional for questionnaires'],
        },
        {
          version: '1.2.9',
          date: '2023-10-16',
          changes: [
            'Add: Structured tags support for videos',
            'Add: API endpoints for video search by tags',
          ],
        },
        {
          version: '1.3.0',
          date: '2023-10-27',
          changes: [
            'Add: By card payment method',
            'Add: Bank account selection required for payments with bank transfer',
            'Update: Payment user group init value',
          ],
        },
        {
          version: '1.3.1',
          date: '2023-12-12',
          changes: [
            'Add: Finalized status for recordings with corresponding rights',
            'Add: Virtual questionnaire field support',
            'Update: Increased JWT security',
            'Update: Spring Boot upgrade to 2.7.18',
            'Update: Allow recording save without dynamic field input value',
            'Update: Set to not automatically return to list upon recordings save',
          ],
        },
        {
          version: '1.3.2',
          date: '2023-12-15',
          changes: [
            'Add: Support for Centers for digital recordings',
            'Fix: Decimal values save for digital recordings',
          ],
        },
        {
          version: '1.3.3',
          date: '2024-01-03',
          changes: [
            'Add: Working group targeting for polls',
            'Fix: Select multiple values save for digital recordings',
          ],
        },
        {
          version: '1.3.4',
          date: '2024-01-08',
          changes: [
            'Update: Poll functionality improvements',
          ],
        },
        {
          version: '1.3.5',
          date: '2024-01-15',
          changes: [
            'Update: Poll submission validations',
            'Fix: Password reset completion',
            'Fix: Payment redirect for specific user groups',
          ],
        },
        {
          version: '1.3.6',
          date: '2024-01-17',
          changes: [
            'Add: Poll submissions committee support',
          ],
        },
        {
          version: '1.3.7',
          date: '2024-03-15',
          changes: [
            'Add: Your account will be locked notice',
            'Add: Auto reset password email after account lock',
          ],
        },
        {
          version: '1.4.0',
          date: '2024-06-04',
          changes: [
            'Add    : Upgrade to Spring Boot 3',
            'Add    : Upgrade to Java 21',
            'Add    : Upgrade to Apache Tomcat 10',
            'Add    : Member search by mobile number',
          ],
        },
        {
          version: '1.4.1',
          date: '2024-06-23',
          changes: [
            'Fix: Member sorting by first and last name',
          ],
        },
        {
          version: '1.4.2',
          date: '2024-07-08',
          changes: [
            'Add    : Recording sections dependent fields',
            'Add    : Recording fields dependent fields',
          ],
        },
        {
          version: '1.4.3',
          date: '2024-08-03',
          changes: [
            'Add    : Support for negative dependent field values',
            'Add    : Support for multiple dependent field values',
          ],
        },
        {
          version: '1.4.4',
          date: '2024-09-24',
          changes: [
            'Add    : Support for multiple select dependent field values',
          ],
        },
        {
          version: '1.4.5',
          date: '2024-10-24',
          changes: [
            'Add    : Vimeo integration parameters',
          ],
        },
        {
          version: '1.4.6',
          date: '2024-11-22',
          changes: [
            'Add    : Locked account email notification',
            'Fix    : Website REST API integration',
          ],
        },
        {
          version: '1.4.7',
          date: '2024-11-30',
          changes: [
            'Add    : Hideable option for recording fields dependent fields',
            'Add    : Patient code title rename option for recordings',
            'Add    : Patient code field hide option for recordings',
          ],
        },
        {
          version: '1.4.8',
          date: '2024-12-14',
          changes: [
            'Add    : Patient code required option for recordings',
            'Add    : Patient initials field hide option for recordings',
            'Update : Spring boot upgrade to version 3.3.6',
            'Fix    : Digital recordings publish valdation for null fields',
          ],
        },
        {
          version: '1.4.9',
          date: '2025-02-15',
          changes: [
            'Add    : Reset questionnaire answers functionality',
          ],
        },
        {
          version: '1.5.0',
          date: '2025-03-17',
          changes: [
            'Add    : Autocomplete field form',
            'Add    : Field with history search functionality for recordings',
            'Add    : Print functionality for recordings',
          ],
        },
        {
          version: '1.5.1',
          date: '2025-03-20',
          changes: [
            'Fix    : Autocomplete field error handling',
          ],
        },
        {
          version: '1.5.2',
          date: '2025-03-23',
          changes: [
            'Fix    : Dropdown fields population',
            'Fix    : Autocomplete freeza',
            'Fix    : Print functionality for recordings wait for all values to load',
          ],
        },
      ]
    },
  },
}
</script>

<style lang="scss" scoped>
h5 {
  margin: 1rem 0;
}

ul {
  padding: 2rem;
  background-color: #f9fafb;
  border-radius: 8px;
  list-style-type: '\2013';
  text-indent: 5px;
}
</style>
